<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Целевые значения">
      <el-row :gutter="20">
        <el-col
          v-for="n in scaleConfig.varsAmount"
          :key="n"
          :span="scaleConfig.viewSpan"
        >
          <el-form-item>
            <el-input v-number-format v-model="scale.targets['col'+n]" :disabled="!fieldsPermissions['scale.targets'] || scaleName !== 'scale_multiple_3_percent' || n !== 2">
              <template slot="prepend">
                {{
                  scaleConfig.colsConfig && scaleConfig.colsConfig['col'+n] && scaleConfig.colsConfig['col'+n].name
                    ? scaleConfig.colsConfig['col'+n].name
                    : 'Цель' + n
                }}
              </template>
              <template
                v-if="scaleConfig.colsConfig && scaleConfig.colsConfig['col'+n] && scaleConfig.colsConfig['col'+n].percentFrom"
                slot="append"
              >
                {{ valueFromPercent(scaleConfig.colsConfig['col'+n].percentFrom, scale.targets['col'+n]) }}
              </template>
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row type="flex" justify="space-between" :gutter="20">

      </el-row>
    </el-form-item>
    <el-form-item
      label="Выплаты за достижения, %"
    >
      <el-row :gutter="20">

        <el-col
          v-for="n in scaleConfig.varsAmount"
          :key="n"
          :span="scaleConfig.viewSpan"
        >
          <el-form-item prop="target_1">
            <el-input v-number-format v-model="scale.pays['col'+n]" :disabled="!fieldsPermissions['scale.pays']">
              <template slot="prepend">
                {{
                  scaleConfig.colsConfig && scaleConfig.colsConfig['col'+n] && scaleConfig.colsConfig['col'+n].name
                    ? scaleConfig.colsConfig['col'+n].name
                    : 'Цель' + n
                }}
              </template>
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";

export default {
  name: "card-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {
    ...mapGetters('settings', {
      settings: 'settings',
    }),
    valueFromPercent() {
      return (from, value) => {
        value = value ? value : '0';
        value = parseFloat(value.replace(',', '.'));

        from = this.scale.targets[from] ? this.scale.targets[from] : '0';
        from = parseFloat(from.replace(',', '.'));

        let counted = from * value / 100;
        return Math.round(counted * 100)/100;
      }
    }
  },


  data() {
    return {
      scale: {
        name: null,
        amount: null,
        unit: null,

        targets: {},
        pays: {},

        fact: null,
      },

      rules: {}


    }
  },

  methods: {
    afterFillScaleFromData(){
      this.scale.name = this.scaleName;
      this.scale.amount = this.scaleConfig.varsAmount;
      let scale = structuredClone(this.scale);
      scale.targets = scale.targets || {};
      scale.pays = scale.pays || {};

      this.scale.targets = {};
      this.scale.pays = {};
      for( let n=1; n <= this.scaleConfig.varsAmount; n++ ){
        if( scale.targets['col'+n] === undefined ){
          this.$set(this.scale.targets, 'col'+n, null);
        } else {
          this.$set(this.scale.targets, 'col'+n, scale.targets['col'+n]);
        }
        if( scale.pays['col'+n] === undefined ){
          this.$set(this.scale.pays, 'col'+n, null);
        } else {
          this.$set(this.scale.pays, 'col'+n, scale.pays['col'+n]);
        }
      }
    }
  }
}
</script>

<style>

</style>